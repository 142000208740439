@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Manrope');


body {
  margin: 0;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sign-up-form input {
  padding: 0.75rem;
  background-color: #fff;
  border-radius: 5px;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

.sign-up-form input:focus {
  outline: 0px;
  border-color: hsla(210, 96%, 45%, 50%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
}

.next-button {
  background-color: #414ed8;
  color: #fffffd;
  text-align: center;
  border-radius: 0.8rem;
  padding: 1rem 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 200px;
  font-weight: 700;
  line-height: 120%;
  position: relative;
  overflow: hidden;
}

.buy-button {
  background-color: #414ed8;
  color: #fffffd;
  text-align: center;
  border-radius: 0.8rem;
  padding: 1rem 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  font-weight: 700;
  line-height: 120%;
  position: relative;
  overflow: hidden;
}

.button .button__circle {
  --x-circle: 0;
  --y-circle: 0;
  --transition-expo-out: cubic-bezier(0.16, 1, 0.3, 1);
  --transition-expo-in: cubic-bezier(0.7, 0, 0.84, 0);
  --transition-cubic-out: cubic-bezier(.09,.56,.61,.99);

  position: absolute;
  top: var(--y-circle);
  left: var(--x-circle);
  background-color: #1d289c;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 1s var(--transition-expo-out); /*<= Leave Transition */
  z-index: 0;
  pointer-events: none;
}

.button:hover .button__circle {
  transform: scale(100);
  transition: transform 1.2s var(--transition-cubic-out); /* <= Enter Transition */
}